.rootView {
    height: 100vh;
    display: flex;
}

.rootContent {
    flex: 1;
    overflow-y: scroll;
}

.shadow {
    box-shadow: 5px 0px 10px #AAAAAA66;
}

.centerContent {
    display: flex;
    justify-content: center;
    align-items: center;
}