.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #C4C4C4;
}

.infoContainer {
    display: flex;
    align-items: center;
}

.infoName {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}

.textTitle {
    font-size: 24px;
    font-weight: bold;
}

.textUserName {
    font-weight: bold;
    margin-left: 5px;
    color: #52575C;
    font-size: 16px;
}
.textInfo {
    margin-left: 5px;
    margin-top: 3px;
    font-size: 13px;
    color: #52575C;
}