.search {
    height: 32px;
    width: 250px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 10px;
    margin-right: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 5px #bbb;
}

.iconSearch {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
}

.input {
    flex: 1;
    padding-top: 0px;
    padding-bottom: 0px;
    align-items: center;
}

.buttonContainer {
    flex: auto;
    width: 80px;
    height: 32px;
    align-items: center;
}
