.container {
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.3);
}

.containerTab {
    display: flex;
}

.viewTab {
    display: inline-block;
    flex-flow: column;
    padding-bottom: 10px;
    transform: translateY(-7%);
    border: none;
    padding: 0;
    margin-right: 15px;
    background-color: transparent;
    font-size: 15px;
}

.indicator {
    height: 5px;
    border-radius: 2.5px;
}

.viewTitle {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.viewResoultProgress {
    position: relative;
    top: -35px;
    width: 100%;
}

.viewTitleVideo {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 14px;
}

.textProgress {
    position: absolute;
    top: 0;
    font-size: 17px;
    font-weight: 800;
    color: #96D962;
    font-family: 'Anton', sans-serif;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 5px;
    margin-top: 4px;
}

.boxProgress {
    position: relative;
}

.viewProgress {
    width: 100%;
    height: 7px;
    border-radius: 10px;
    background-color: #E7E7E7;
    overflow: hidden;
    position: relative;
}

.valueProgress {
    height: 7px;
    border-right: 1.5px solid #333;
    background: #96D962;
    position: absolute;
}

.viewTitleLeg {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 5px;
    transform: translateY(-20%);
}

.viewSteper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
}

.viewLeg {
    flex: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
}

.leg {
    width: 15px;
    height: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.titLeg {
    font-size: 14px;
    color: #000;
}

.textDuration {
    font-size: 13px;
    color: #333;
    margin-top: 7px;
}

.overview {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    padding-top: 25px;
    padding-bottom: 10px;
}

.viewProgressResult {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    margin-left: 30px;
    margin-right: 30px;
}

.makeProgress {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 300;
    margin-top: 12px;
    background-color: #96D962;
}

.viewInfo {
    font-size: 14px;
    font-weight: 300;
    margin-left: 30px;
    margin-right: 30px;
}

.viewItem {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
}