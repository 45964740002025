.content {
    /* display: flex; */
     justify-content: center; 
     align-items: center; 
    margin-top: 40px;
}

.overview {
    width: 60%;
    display: flex;
    align-items: center;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    margin: auto;
    margin-top: 30px;
    padding-top: 25px;
    padding-bottom: 10px;
}

.viewProgressResult {
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    font-size: 14px;
}

.makeProgress {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    min-width: 55px;
    text-align: center;
    color: white;
    font-weight: 500;
}

.viewInfo {
    font-size: 14px;
    font-weight: 300;
}

.viewItem {
    display: flex;
    margin-top: 7px;
    margin-bottom: 7px;
}

.boxCharts {
    position: relative;
    flex: auto;
    margin-top: 20px;
    padding-top: 15px;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 25px #ededed
}

.viewTitle {
    display: flex;
    padding-left: 15px;
    padding-right: 20px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    font-size: 14px;
}

.titleChart {
    font-size: 15px;
    font-weight: 600;
}

.buttonCourse {
    display: flex;
    border: none;
    z-index: 1;
    padding: 0;
}

.buttonCourse1 {
    display: inline;
    z-index: 1;
    padding-bottom: 5px;
    border-radius: 0;
    border-bottom: 1px solid #96D962;
}

.viewItem {
    min-width: 140px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.viewItem:hover {
    background-color: rgba(150, 217, 98, 0.3);
}

.formControl {
    min-width: 120px;
    font-size: 14px;
}

.arrow_box {
    padding: 5px;
}

.arrowBox {
    padding: 5px;
}
