.container {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.content {
    width: 90%;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.viewInfo {
    display: flex;
    align-items: center;
}

.viewItemInfo {
    display: flex;
    align-items: flex-end;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
}

.input {
    flex: 1;
    padding-top: 0px;
    padding-bottom: 0px;
    align-items: center;
}

.search {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 32px;
    width: 300px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 10px;
    margin-right: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 5px #bbb;
}

.iconSearch {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
}
