.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.content {
    width: 800px;
    height: 450px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(16, 30, 115, 0.08)
}

.logo {
    margin-top: 55px;
    margin-bottom: 12px;
}

.textCenter {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 50px;
    display: block;
    text-align: center;
}

.box {
    width: 400px;
    display: block;
    text-align: center;
}

.inputContainer {
    width: 100%;
    position: relative;
    margin-bottom: 25px;
    border: 0;
    border-bottom: 0.5px solid #333;
    background: white;
    width: 100%;
    padding: 8px 0 8px 0;
    font-size: 14px;
    text-align: center;
}

.inputContainer:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid #e74c3c;
}

.btn {
    width: 100%;
    height: 35px;
    border: 0;
    padding: 10px 20px;
}

.textForgotPass {
    font-size: 14px;
    text-decoration: none;
    color: #000;
    padding-top: 25px;
    margin-top: 50px;
}

.textConfirm {
    font-size: 14px;
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 30px;
    display: block;
    text-align: center;
}

.viewCheck {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #41A336;
    margin: auto;
}