body {
  background-color: #F6F8FB;
  margin: auto;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: black !important;
}

.Mui-disabled {
  opacity: 0.5;
}

#__react-alert__ div div div {
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
  text-transform: initial !important;
  font:inherit !important;
  padding: 8px 10px;
}
.apexcharts-tooltip {
  padding: 6px;
}