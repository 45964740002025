.container {
    width: 284px;
    background-color: white;
    box-shadow: 10px 0px 15px #AAAAAA66;
}

.label {
    color: #52575C;
    font-weight: 500;
    margin-left: 20px;
    margin-bottom: 10px;
}

.logo {
    padding-left: 25px;
    margin-top: 30px;
    margin-bottom: 40px;
}

.viewTitle{
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
}

.title{
    font-weight: 600;
    margin-left: 5px;
}

.titleItem:hover {
    background-color: #f0f0f0;
}

.titleItem{
    display: block;
    text-decoration: none;
    color: #52575C;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
}

.titleItemActive:hover{
    background-color: #f0f0f0;
}