.container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 10px;
}

.avatar {
    width: 123.72px;
    height: 123.72px;
    border-radius: 60px;
}

.header {
    flex: auto;
    display: block;
    margin-left: 30px;
    position: relative;
}

.viewInfo {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.viewItemInfo {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 14px;
}