.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.viewIcon {
    display: flex;
    align-items: center;
}

.boxModal {
    display: flex;
    flex-flow: column;
    padding: 20px;
}

.content {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}

.viewInput {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 14px;
}

.inputContainer {
    flex: auto;
    border-width: 0px;
    padding-left: 5px;
    margin-bottom: -2px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
}

.inputContainer:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid #96D962;
}

.btnSuccess {
    width: 80%;
    height: 35px;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 20px;
}