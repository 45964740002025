.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.contentContainer {
    flex: 1;
    padding: 20px;
}

.title {
    font-weight: bold;
    font-size: 20px;
}

.containerBottom {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}
